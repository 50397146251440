<template>
  <loader-spinner v-if="loading"
                  class="c c--vh" />
  <div v-else
       class="sidepanel__content"
       data-cy="operations.sidepanel.container">
    <transition name="fade">
      <file-viewer v-if="displayFileViewer"
                   ref="fileviewer"
                   class="side-fileviewer sidepanel-double sidepanel-double--fileviewer"
                   @close="closeFileViewer" />
    </transition>
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <div class="transaction-summary transaction-summary--no-border">
          <h2 class="transaction-amount"
              :class="{ 'text-green': modelForm.type === 'credit', 'text-red': modelForm.type === 'debit', 'text-muted': isRejected }">
            {{ formatAmount(modelForm.amount, account.currency, { isNegative: isDebit }) }}
          </h2>
          <p v-if="hasFx"
             class="fx-currency text-muted">
            {{ formatAmount(modelForm.fx_amount, modelForm.fx_currency, { currencyDisplay: 'code' }) }}
          </p>
          <p class="transaction-name">
            {{ modelForm.label }}
          </p>
          <core-badge v-if="isOperationPreAuth"
                      v-tooltip="{ content: $t('poptip.pre_auth_fuel.content'), theme: 'poptip' }"
                      v-bind="getBadgeProps(isRejected ? 'rejected' : 'info')"
                      :size="ECoreBadgeSize.Large"
                      :icon-name="isRejected ? ECoreIconList.Block : ECoreIconList.Info"
                      class="transaction-badge"
                      :value="isRejected ? $t('general.rejected') : $t('general.pre_authorization')" />
          <core-badge v-else-if="isRejected"
                      :theme="ECoreBadgeTheme.Danger"
                      :fill="ECoreBadgeFill.Shaded"
                      :size="ECoreBadgeSize.Large"
                      :icon-name="ECoreIconList.Block"
                      class="transaction-badge"
                      :value="$t('general.rejected')">
            <template v-if="modelForm.reject_reason"
                      #default="{ value }">
              <TooltipPoptip :value="value"
                             :title="$t('table.reject_motive')"
                             :message="modelForm.reject_reason" />
            </template>
          </core-badge>
          <span v-else
                class="d-flex flex-col mt-2">
            <core-badge v-if="isPending"
                        v-tooltip="{ content: $t('poptip.operation_to_come.content'), theme: 'poptip' }"
                        :theme="ECoreBadgeTheme.GrayLow"
                        :fill="ECoreBadgeFill.Shaded"
                        :value="$t('general.to_come')"
                        :size="ECoreBadgeSize.Large"
                        class="block-centered" />
            <core-badge v-else-if="modelForm.required_fields.length"
                        :theme="ECoreBadgeTheme.Danger"
                        :fill="ECoreBadgeFill.Shaded"
                        :value="$t('control_rule.missing_information.input_info') "
                        :icon-name="ECoreIconList.ErrorFilled"
                        :size="ECoreBadgeSize.Large"
                        class="block-centered">
              <template #default="{ value }">
                <TooltipPoptip :value="value"
                               :title="$t('control_rule.missing_information.title')"
                               :message="$t('control_rule.missing_information.subtitle')"
                               :placement="center" />
              </template>
            </core-badge>
            <core-badge v-else-if="hasProofsToJustify"
                        v-bind="operationProofDeadlineBadge.badgeBind"
                        :size="ECoreBadgeSize.Large"
                        class="block-centered">
              <template #default="{ value }">
                <TooltipPoptip :value="value"
                               :title="operationProofDeadlineBadge.poptipTitle"
                               :message="operationProofDeadlineBadge.poptipContent" />
              </template>
            </core-badge>
            <core-badge v-else-if="hasComplianceStatus"
                        v-bind="operationComplianceBadge.badgeBind"
                        :size="ECoreBadgeSize.Large"
                        class="block-centered">
              <template #default="{ value }">
                <TooltipPoptip :value="value"
                               :title="operationComplianceBadge.poptipTitle"
                               :message="operationComplianceBadge.poptipContent" />
              </template>
            </core-badge>
            <core-badge v-else-if="isAcceptedOperation"
                        :theme="ECoreBadgeTheme.Info"
                        :fill="ECoreBadgeFill.Shaded"
                        :value="acceptedOperationBadge.badgeLabel"
                        :size="ECoreBadgeSize.Large"
                        :icon-name="ECoreIconList.InfoOutlined"
                        class="block-centered">
              <template #default="{ value }">
                <TooltipPoptip :value="value"
                               :title="acceptedOperationBadge.poptipTitle"
                               :message="acceptedOperationBadge.poptipContent" />
              </template>
            </core-badge>
            <div v-if="(hasPermission($permissions.operationsDocumentsWrite) && isEditable) || (hasPermission($permissions.operationsDocumentsRead) && (hasDocument || hasBill))"
                 class="document-actions-wrapper">
              <button type="button"
                      class="btn btn-proofs"
                      data-cy="operations.sidepanel.file"
                      :class="proofButton.btnClass"
                      @click="handleProofAction">
                <span class="btn-icon btn-icon--left">
                  <component :is="proofButton.icon"
                             class="ic ic--24 ic--gray" />
                </span>
                <span class="btn__label">{{ proofLabel }}</span>
              </button>
              <component-popover v-if="(documentHasActions || canUpdateCompliance)"
                                 trigger="click"
                                 close-on-click
                                 popover-style="margin-top: 1.2rem"
                                 popover-class="popover-actions-menu popover--pull-right"
                                 @click.stop>
                <template #trigger>
                  <button class="btn btn--icon btn--outline btn-more-actions">
                    <i class="btn-icon">
                      <ic-more class="ic ic--gray" />
                    </i>
                  </button>
                </template>
                <template v-if="documentHasActions">
                  <p class="document-proofs-heading upper">{{ $tc('general.proof', 1) }}</p>
                  <div v-for="(action, index) in documentActions"
                       :key="index"
                       :class="action.class"
                       class="popover__item"
                       @click="action.action">
                    <div>{{ action.label }}</div>
                  </div>
                </template>
                <hr v-if="documentHasActions && documentExpenseActions.length"
                    class="divider">
                <div v-if="documentExpenseActions.length"
                     class="operation-compliance">
                  <div v-for="(action, index) in documentExpenseActions"
                       :key="index"
                       :class="action.class"
                       class="popover__item"
                       @click="action.action">
                    <div class="popover__item__icon">
                      <component :is="action.iconName"
                                 class="ic ic--16"
                                 :class="action.iconClass" />
                    </div>
                    <div class="strong"
                         :class="action.labelClass">{{ action.label }}</div>
                  </div>
                </div>
              </component-popover>
            </div>
          </span>
        </div>
      </div>
      <div>
        <div class="sidepanel-details">
          <div v-if="isCategoryCard"
               class="sidepanel-block">
            <div class="sidepanel-block-body no-header">
              <component-dropdown v-if="hasPermission($permissions.usersLightRead)"
                                  label="user"
                                  :dropdown-width="35.4"
                                  :dropdown-height="40"
                                  :dropdown-margin-top="1"
                                  dropdown-position="right"
                                  search
                                  multiselect
                                  async-url="/users"
                                  :async-url-params="{ operation_uuid: modelForm.uuid, scenario: 'light' }"
                                  :label-filter="userFilter"
                                  has-item-picture
                                  :model="attributions"
                                  :disabled="!isEditable"
                                  @select="onAttributionSelected">
                <template #trigger>
                  <transaction-user :pictures="attributions.map(e => e.picture)"
                                    :user-names="attributions.map(el => `${el.first_name} ${el.last_name}`)"
                                    :missing-field="isMissingField('operation_user')"
                                    :text="$t('form.operations.attributions.assigned_to')"
                                    :placeholder="$t('form.operations.attributions.add_users')"
                                    :is-editable="isEditable" />
                </template>
              </component-dropdown>
              <transaction-user v-else
                                :pictures="attributions.map(el => el.picture)"
                                :user-names="attributions.map(el => `${el.first_name} ${el.last_name}`)"
                                :text="$t('form.operations.attributions.assigned_to')" />
            </div>
          </div>

          <div v-if="isCategoryTransfer && modelForm.user"
               class="sidepanel-block-body pb-0 mt-2">
            <transaction-user :pictures="[modelForm.user.picture]"
                              :user-names="[`${modelForm.user.first_name} ${modelForm.user.last_name}`]"
                              :text="$t('general.initiated_by')"
                              class="mb-0" />
          </div>
          <validation-form ref="form"
                           :validation-schema="schema"
                           @keydown.enter.prevent>
            <div class="sidepanel-block">
              <div class="sidepanel-block-header">
                <h3 class="sidepanel-block-header-title">
                  {{ $t('sidepanel.operation.title') }}
                </h3>
              </div>
              <div class="sidepanel-block-body">
                <div class="sidepanel-details__entry">
                  <div class="entry-label text-muted">
                    {{ $t('table.type') }}
                  </div>
                  <div class="entry-value right">
                    {{ modelForm.category_label }}
                  </div>
                </div>
                <div v-if="(isCategoryTransfer || isCategoryCard) && isDebit"
                     class="sidepanel-details__entry">
                  <div class="entry-label text-muted">
                    {{ $t(createdDateLabel) }}
                  </div>
                  <div class="entry-value right">
                    {{ formatDateText(modelForm.created_at, 'lll') }}
                  </div>
                </div>
                <div v-if="isValueDateVisible"
                     class="sidepanel-details__entry">
                  <div class="entry-label text-muted">
                    {{ $t('general.value_date') }}
                  </div>
                  <div class="entry-value right">
                    {{ formatDateText(modelForm.value_date, 'll') }}
                  </div>
                </div>
                <div v-if="isAccountingDateVisible"
                     class="sidepanel-details__entry">
                  <div class="entry-label text-muted">
                    {{ $t('general.accounting_date') }}
                  </div>
                  <div class="entry-value right">
                    {{ formatDateText(modelForm.accounting_date, 'll') }}
                  </div>
                </div>
                <div v-if="isSettlementDateVisible"
                     class="sidepanel-details__entry">
                  <div class="entry-label text-muted">
                    {{ $t('general.settlement_date') }}
                  </div>
                  <div class="entry-value right">
                    {{ formatDateText(modelForm.settlement_date, 'll') }}
                  </div>
                </div>
                <div v-if="((isCategoryTransfer && isDebit) || (isCategoryRefund)) && modelForm.beneficiary">
                  <div v-if="modelForm.beneficiary.label"
                       class="sidepanel-details__entry">
                    <div class="entry-label text-muted">
                      {{ $t('general.beneficiary') }}
                    </div>
                    <div class="entry-value right">
                      {{ modelForm.beneficiary.label }}
                    </div>
                  </div>
                  <div v-if="modelForm.beneficiary.iban"
                       class="sidepanel-details__entry">
                    <div class="entry-label text-muted">
                      {{ $t('general.iban') }}
                    </div>
                    <div class="entry-value right">
                      {{ formatIban(modelForm.beneficiary.iban) }}
                    </div>
                  </div>
                </div>
                <div v-if="categories.length || isEditable"
                     class="sidepanel-details__entry">
                  <div class="entry-label text-muted"
                       :class="{ 'missing-field': isMissingField('operation_category') }">
                    {{ $t('general.category') }}
                  </div>
                  <div class="entry-value right">
                    <div class="select select--inline select--rtl"
                         :class="{ 'select--disabled': !isEditable }">
                      <component-dropdown label="categories"
                                          multiselect
                                          search
                                          :dropdown-width="35.4"
                                          :can-add-item="hasPermission($permissions.categoriesWrite)"
                                          async-url="/categories"
                                          :async-url-params="categoriesUrlParams"
                                          has-icon
                                          :model="categories"
                                          :disabled="!isEditable"
                                          @select="onCategoriesSelected"
                                          @new-item="onNewItemAdded">
                        <template #trigger="slotProps">
                          <div v-tooltip="{ content: slotProps.showDropdown ? false : tooltipCategoriesLabel, theme: 'tooltip', html: true }"
                               class="form-control"
                               :class="{ 'text-muted': !categories.length }">
                            {{ dropdownCategoriesLabel }}
                          </div>
                        </template>
                      </component-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <multiple-vat v-if="canReadVat"
                          :initial-vat="vat"
                          :editable="isEditable && canEditVat"
                          :form-submitted="formSubmitted"
                          :vat-rates="vatRates"
                          :is-missing-field="isMissingField('vat_amount')"
                          :operation-amount="modelForm.amount"
                          @update:initial-vat="value => operation.detail.vat = value" />
            <div v-if="!isRejected && (canReadAffectations || canReadChargeBack)"
                 class="sidepanel-block">
              <div class="sidepanel-block-header">
                <h3 class="sidepanel-block-header-title">
                  {{ $t('sidepanel.accounting.title') }}
                </h3>
              </div>
              <div class="sidepanel-block-body">
                <category-detail-fields :operation="operation"
                                        :disabled="!isEditable"
                                        @change="onCategoryDetailChange" />
                <sidepanel-affectations v-if="canReadAffectations"
                                        :can-edit-affectations="canEditAffectations"
                                        :can-write-affectations="canWriteAffectations"
                                        :affectations="affectations"
                                        @affectations-selected="onAffectationsSelected"
                                        @new-affectation-added="onNewAffectationAdded" />
                <div v-if="canReadChargeBack"
                     class="sidepanel-details__entry">
                  <div class="entry-label text-muted"
                       :class="{ 'missing-field': isMissingField('charge_back') }">
                    {{ $t('form.charge_back.label') }}
                  </div>
                  <div class="entry-value right">
                    <div class="select select--inline select--rtl"
                         :class="{ 'select--disabled': !isChargeBackEditable }">
                      <component-dropdown label="charge-back"
                                          :dropdown-width="35.4"
                                          :values="chargeBackValues"
                                          :model="operation.detail.charge_back"
                                          :disabled="!isChargeBackEditable"
                                          @select="onChargeBackSelected">
                        <template #trigger>
                          <div class="form-control">
                            {{ chargeBackLabel }}
                          </div>
                        </template>
                      </component-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidepanel-block">
              <div class="sidepanel-block-header">
                <h3 class="sidepanel-block-header-title"
                    :class="{ 'missing-field': isMissingField('comment') }">
                  {{ $t('form.personal_note.label') }}
                </h3>
              </div>
              <div class="sidepanel-block-body">
                <div class="sidepanel-details__entry">
                  <input-note v-model="operation.comment"
                              :placeholder="$t('form.personal_note.placeholder')"
                              :disabled="!isOwner && isAcceptedOperation" />
                </div>
              </div>
            </div>
            <div v-if="hasDocumentsHistories"
                 class="sidepanel-block">
              <div class="sidepanel-block-header expandable"
                   @click="documentsHistoriesIsExpanded = !documentsHistoriesIsExpanded">
                <h3 class="sidepanel-block-header-title">
                  {{ $t('form.document_history.title') }}
                </h3>
                <ic-chevron down
                            :class="{ selected: documentsHistoriesIsExpanded }"
                            class="ic ic--20 ic--gray ic--filter" />
              </div>
              <div class="collapsible-wrapper"
                   :class="{ open: documentsHistoriesIsExpanded }">
                <div class="sidepanel-block-body collapsible-content">
                  <component-timeline :key="modelForm.documents_histories.length"
                                      type="lite">
                    <template #body>
                      <component-timeline-item v-for="(document, index) in modelForm.documents_histories"
                                               :id="index"
                                               :key="index"
                                               type="lite"
                                               :title="formatTitle(document)"
                                               :subtitle="formatSubtitle(document)"
                                               :is-last-item="index === modelForm.documents_histories.length - 1" />
                    </template>
                  </component-timeline>
                </div>
              </div>
            </div>
          </validation-form>
        </div>
      </div>
    </div>
    <component-sidepanel-footer v-if="didDetailsChange || modelForm.expenseToValidate">
      <transition name="fade"
                  mode="out-in">
        <component-button v-if="didDetailsChange"
                          :label="$t('button.save')"
                          wrapper-class="btn--block btn--sm btn--primary"
                          @click="saveOperation" />
        <div v-else
             class="btn-grouped">
          <button class="btn btn--sm btn--gray"
                  @click="nonCompliantExpense">
            {{ $t('button.reject') }}
          </button>
          <component-button wrapper-class="btn--sm btn--primary"
                            :label="$t('button.validate')"
                            @click="onAcceptExpense" />
        </div>
      </transition>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { CoreBadge, CoreIcon, ECoreBadgeFill, ECoreBadgeSize, ECoreBadgeTheme, ECoreIconList, ECoreIconTheme } from '@common/core-ui'
import { cloneDeep, isEqual } from 'lodash'
import { storeToRefs } from 'pinia'
import { Form as ValidationForm } from 'vee-validate'
import * as yup from 'yup'

import { EFeature, hasFeature } from '@/config/features'
import store from '@/config/store'
import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDateText, formatTime, relativeTimeDifference } from '@/helpers/utils/date'
import { formatIban } from '@/helpers/utils/iban'
import { showToastSuccess, showToastWarning } from '@/helpers/utils/notification'
import { formatAmount, getCurrencySymbol } from '@/helpers/utils/number'
import { arrayInputLabel, arrayInputTooltip } from '@/helpers/utils/text'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useExpenseStore } from '@/stores/expense'
import { useOperationStore } from '@/stores/operation'

import MultipleVat from '@/pages/operations/components/MultipleVat.vue'
import ClaimNonCompliantOperation from '@/pages/operations/modal/ClaimNonCompliantOperation.vue'

import ComponentButton from '@/components/Button.vue'
import CategoryDetailFields from '@/components/CategoryDetailFields.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import FileViewer from '@/components/FileViewer.vue'
import InputNote from '@/components/InputNote.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import NumberInput from '@/components/NumberInput.vue'
import ComponentPopover from '@/components/Popover.vue'
import SidepanelAffectations from '@/components/SidepanelAffectations.vue'
import SidepanelBlock from '@/components/SidepanelBlock.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcAnalyze from '@/components/svg/icons/ic-analyze.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcBlock from '@/components/svg/icons/ic-block.vue'
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcDocumentInvalid from '@/components/svg/icons/ic-document-invalid.vue'
import IcFeedback from '@/components/svg/icons/ic-feedback.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcMore from '@/components/svg/icons/ic-more.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'
import ComponentTimeline from '@/components/timeline/Timeline.vue'
import ComponentTimelineItem from '@/components/timeline/TimelineItem.vue'
import TooltipPoptip from '@/components/TooltipPoptip.vue'
import TransactionUser from '@/components/TransactionUser.vue'

export default {
  components: {
    ValidationForm,
    MultipleVat,
    TooltipPoptip,
    CoreBadge,
    CoreIcon,
    IcBlock,
    InputNote,
    TransactionUser,
    IcInfo,
    IcMore,
    ComponentButton,
    ComponentDropdown,
    ComponentSidepanelFooter,
    FileViewer,
    ComponentPopover,
    LoaderSpinner,
    NumberInput,
    CategoryDetailFields,
    IcChevron,
    ComponentTimeline,
    ComponentTimelineItem,
    SidepanelAffectations,
    SidepanelBlock
  },

  props: {
    model: {
      type: Object,
      required: true
    },

    type: {
      type: String,
      default: 'operation'
    }
  },

  emits: ['dropped-files-upload'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const expenseStore = useExpenseStore()
    const operationStore = useOperationStore()

    const { account } = storeToRefs(accountStore)
    const { sidePanel } = storeToRefs(appStore)
    const { operations, operationAffectations } = storeToRefs(accountStore)
    const { operationCategories, operationCategoriesCard, processingAnalyze } = storeToRefs(operationStore)
    const { expenses } = storeToRefs(expenseStore)

    return { account, sidePanel, appStore, operations, operationAffectations, expenses, expenseStore, processingAnalyze, operationCategories, operationCategoriesCard, operationStore }
  },

  data () {
    return {
      abortController: null,
      store,
      loading: true,
      category_id: null,
      categories: [],
      affectations: [],
      attributions: [],
      vat: null,
      vatRates: [],
      vatError: false,
      displayFileViewer: false,
      formSubmitted: false,
      operation: {
        detail: {}
      },

      modelForm: {},
      documentsHistoriesIsExpanded: true,

      schema: yup.object().shape({
        vat: yup
          .array()
          .of(
            yup.object().shape({
              amount: yup.number().when('rate', {
                is: val => val !== null,
                then: () => yup.number().required(),
                otherwise: () => yup.number().nullable()
              }),
              rate: yup.number().when('amount', {
                is: val => val !== null,
                then: () => yup.number().required(),
                otherwise: () => yup.number().nullable()
              })
            }, ['rate', 'amount'])
          )
      })
    }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    ECoreIconList () {
      return ECoreIconList
    },

    ECoreIconTheme () {
      return ECoreIconTheme
    },

    hasDocumentsHistories: {
      get () {
        return this.modelForm?.documents_histories?.length
      },

      cache: false
    },

    isOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    },

    hasComplianceStatus () {
      return this.modelForm.is_compliant !== null
    },

    isCompliant () {
      return this.hasComplianceStatus ? this.modelForm.is_compliant : true
    },

    canUpdateCompliance () {
      return this.modelForm.can_update_compliance
    },

    hasProofsToJustify () {
      return this.modelForm.proof_required_before
    },

    getProofRequiredRelativeTimeDiff () {
      return relativeTimeDifference(this.modelForm.proof_required_before)
    },

    isProofLate () {
      return this.getProofRequiredRelativeTimeDiff.hours < 0
    },

    proofToJustifyLabel () {
      return this.$i18n.t('badge.proof_required.remaining', { diff: this.getProofRequiredRelativeTimeDiff.text })
    },

    isDebit () {
      return this.modelForm.type === 'debit'
    },

    hasDocument () {
      return this.modelForm.documents.length
    },

    isProofInvalid () {
      return this.modelForm.documents_status === 'invalid'
    },

    proofButton () {
      if (this.displayFileViewer) {
        if (!this.isProofInvalid && !this.isProofLost && this.modelForm.documents.length) {
          return { icon: IcAnalyze, btnClass: 'btn--outline btn-analyze' }
        } else if (this.isProofInvalid) {
          return { icon: IcAttachment, btnClass: 'btn--outline btn-attachment' }
        }
      }
      if (this.isProofLost) {
        return { icon: IcWarning, btnClass: 'btn-document-lost' }
      } else if (!this.displayFileViewer && this.isProofInvalid) {
        return { icon: IcDocumentInvalid, btnClass: 'btn--outline btn-document-invalid' }
      } else if (this.hasDocument || this.hasBill) {
        return { icon: IcDocument, btnClass: 'btn--outline btn-document' }
      } else {
        const button = { icon: IcAttachment, btnClass: 'btn--outline btn-attachment' }
        if (this.isMissingField('operation_document')) {
          button.btnClass += ' btn-document-invalid'
        }
        return button
      }
    },

    proofLabel () {
      if (this.displayFileViewer) {
        if (!this.isProofInvalid && !this.isProofLost && this.hasDocument) {
          return this.processingAnalyze ? this.$i18n.t('sidepanel.operation.processing_analyze') : this.$i18n.t('button.analyze_document')
        } else if (this.isProofInvalid) {
          return this.$i18n.t('tooltip.replace_file')
        }
      }
      if (!this.displayFileViewer && this.isProofInvalid) {
        return this.$i18n.t('tooltip.invalid_proof')
      } else if (this.isProofLost) {
        return `${this.$i18n.t('label.reported_lost')}`
      } else if (this.isProofOptional) {
        return this.$i18n.t('button.optional_proof')
      } else if (this.hasDocument || this.hasBill) {
        return this.$i18n.tc('button.show_proofs', this.modelForm.documents.length)
      } else {
        return this.$i18n.t('button.attach_proof')
      }
    },

    isProofOptional () {
      return this.modelForm.documents_status === 'optional'
    },

    isProofLost () {
      return this.modelForm.documents_status === 'lost'
    },

    hasBill () {
      return this.modelForm.bill
    },

    createdDateLabel () {
      if (this.isDebit) {
        if (this.isCategoryTransfer) { return 'general.entry_date' }
        if (this.isCategoryCard) { return 'general.payment_date' }
      }

      return 'general.created_at'
    },

    chargeBackLabel () {
      const value = this.chargeBackValues.find(i => i.value === this.operation.detail.charge_back)
      return value?.label || this.$i18n.t('general.fail')
    },

    chargeBackValues () {
      return [
        {
          label: this.$i18n.t('general.yes'),
          value: true
        },
        {
          label: this.$i18n.t('general.no'),
          value: false
        },
        {
          label: this.$i18n.t('general.undefined'),
          value: null
        }
      ]
    },

    filteredOperationCategories () {
      return this.isCategoryCard ? this.operationCategoriesCard : this.operationCategories
    },

    didDetailsChange () {
      return !isEqual({
        detail: this.modelForm.detail,
        comment: this.modelForm.comment || '',
        affectations: this.modelForm.affectations,
        attributions: this.modelForm.attributions
      }, {
        detail: this.operation.detail,
        comment: this.operation.comment || '',
        affectations: this.operation.affectations,
        attributions: this.operation.attributions
      })
    },

    dropdownCategoriesLabel () {
      return this.categories.length
        ? arrayInputLabel(this.categories, 1)
        : this.$i18n.t('form.common.select')
    },

    tooltipCategoriesLabel () {
      return arrayInputTooltip(this.categories, 1)
    },

    isCategoryTransfer () {
      return this.modelForm.category === 'transfer'
    },

    isCategoryRefund () {
      return this.modelForm.category === 'refund'
    },

    isCategoryDirectDebit () {
      return this.modelForm.category === 'direct_debit'
    },

    isCategoryCard () {
      return this.modelForm.category === 'credit_card'
    },

    hasRestaurantCategory () {
      return this.categories.some(category => category.name === 'restaurant')
    },

    categoriesUrlParams () {
      if (this.isCategoryCard) { return { filter: 'card' } }
      if (this.isCategoryTransfer) { return { filter: 'transfer' } }
      if (this.isCategoryRefund) { return { filter: 'refund' } }
      if (this.isCategoryDirectDebit) { return { filter: 'direct_debit' } }
      return {}
    },

    hasFx () {
      return this.modelForm.fx_amount && this.modelForm.fx_currency
    },

    isRejected () {
      return this.modelForm.status === 'rejected'
    },

    isPending () {
      return this.modelForm.status === 'pending'
    },

    isOperationPreAuth () {
      return this.modelForm.is_pre_auth
    },

    documentHasActions () {
      return this.modelForm.documents_actions && this.modelForm.documents_actions.length
    },

    isAcceptedOperation () {
      return this.modelForm.expense_control.status === 'accepted'
    },

    operationComplianceButton () {
      return this.isCompliant
        ? {
            label: this.$i18n.t('modal.non_compliant_expense.title'),
            iconName: IcBlock,
            iconClass: 'ic--gray',
            action: this.claimOperationCompliance
          }
        : {
            label: this.$i18n.t('button.regularized_expense'),
            iconName: IcCheckCircle,
            iconClass: 'ic--success',
            action: this.claimOperationCompliance
          }
    },

    operationComplianceBadge () {
      const date = formatDateText(this.modelForm.expense_control.control_at, 'll').replace(' ', ' ')
      const compliantDate = this.isCompliant && formatDateText(this.modelForm.compliant_at, 'll').replace(' ', ' ')
      const name = this.modelForm.expense_control.control_user.name
      return this.isCompliant
        ? {
            badgeBind: {
              theme: this.ECoreBadgeTheme.GrayLow,
              fill: this.ECoreBadgeFill.Shaded,
              value: this.$i18n.t('badge.regularized_at', { date: compliantDate }),
              iconName: this.ECoreIconList.CheckCircle
            },

            poptipTitle: this.$i18n.t('poptip.regularized_expense.title'),
            poptipContent: this.$i18n.t('poptip.regularized_expense.content', { date: compliantDate, name })
          }
        : {
            badgeBind: {
              theme: this.ECoreBadgeTheme.Danger,
              fill: this.ECoreBadgeFill.Shaded,
              value: this.$i18n.t('badge.rejected_at', { date }),
              iconName: this.ECoreIconList.Block
            },

            poptipTitle: this.$i18n.t('poptip.rejected_expense.title'),
            poptipContent: this.$i18n.t('poptip.rejected_expense.content', { date, name })
          }
    },

    operationProofDeadlineBadge () {
      return this.isProofLate
        ? {
            badgeBind: {
              theme: this.ECoreBadgeTheme.Danger,
              fill: this.ECoreBadgeFill.Shaded,
              value: this.$i18n.t('badge.late_proof'),
              iconName: this.ECoreIconList.ErrorFilled
            },

            poptipTitle: this.$i18n.t('badge.late_proof'),
            poptipContent: this.$i18n.t('poptip.late_proof.content')
          }
        : {
            badgeBind: {
              theme: this.ECoreBadgeTheme.Warning,
              fill: this.ECoreBadgeFill.Shaded,
              value: this.proofToJustifyLabel,
              iconName: this.ECoreIconList.Warning
            },

            poptipTitle: this.$i18n.t('button.required_proof'),
            poptipContent: this.$i18n.t('poptip.required_proof.content')
          }
    },

    acceptedOperationBadge () {
      const date = formatDateText(this.modelForm.expense_control.control_at, 'll').replace(' ', ' ')
      const name = this.modelForm.expense_control.control_user.name
      let poptipContent = this.$i18n.t('poptip.controlled_expense.content', { date, name })
      if (!this.hasPermission(this.$permissions.operationsWrite)) {
        poptipContent += ` ${this.$i18n.t('poptip.controlled_expense.uneditable')}`
      }

      return {
        badgeLabel: this.$i18n.t('badge.controlled_at', { date }),
        poptipTitle: this.$i18n.t('poptip.controlled_expense.title'),
        poptipContent
      }
    },

    isEditable () {
      return (this.isOwner || !this.isAcceptedOperation)
    },

    isChargeBackEditable () {
      return this.isEditable && hasFeature(EFeature.OperationDetailChargeBackEdition, this.modelForm)
    },

    documentExpenseActions () {
      const actions = []

      if (this.canUpdateCompliance) actions.push(this.operationComplianceButton)

      if (this.type === 'expense') {
        actions.push({
          label: this.$i18n.t('button.request_informations'),
          iconName: IcFeedback,
          iconClass: 'ic--gray',
          action: this.requestMoreInfo
        })

        if (this.modelForm.expense_control?.status !== 'pending') {
          actions.push({
            label: this.$i18n.t('button.cancel_control'),
            labelClass: 'text-error',
            iconName: IcRemove,
            iconClass: 'ic--error',
            action: this.resetExpenseControl
          })
        }
      }

      return actions
    },

    documentActions () {
      return this.modelForm.documents_actions.map(action => {
        switch (action) {
          case 'invalid':
            return {
              label: this.$i18n.t('button.invalid_proof'),
              action: this.setProofInvalid
            }
          case 'claimed':
            return {
              label: this.$i18n.t('button.claim_proof'),
              action: this.claimProof
            }
          case 'required':
            return {
              label: this.$i18n.t('button.required_proof'),
              action: this.setProofRequired
            }
          case 'optional':
            return {
              label: this.$i18n.t('button.optional_proof'),
              action: this.setProofOptional
            }
          case 'lost':
            return {
              label: this.$i18n.t('button.report_lost'),
              action: this.reportLostProof
            }
          case 'found':
            return {
              label: this.$i18n.t('button.proof_found'),
              action: this.setProofRequired
            }
          case 'valid':
            return {
              label: this.$i18n.t('button.valid_proof'),
              action: this.setProofValid
            }
          default:
            return null
        }
      }).filter(el => el !== null)
    },

    canReadAffectations () {
      return this.hasPermission(this.$permissions.operationsAffectationsRead, this.modelForm)
    },

    canEditAffectations () {
      return this.canReadAffectations && this.hasPermission(this.$permissions.operationsAffectationsWrite, this.modelForm)
    },

    canWriteAffectations () {
      return this.canEditAffectations && this.hasPermission(this.$permissions.affectationsWrite)
    },

    canReadChargeBack () {
      return hasFeature(EFeature.OperationDetailChargeBack, this.modelForm)
    },

    canReadVat () {
      return hasFeature(EFeature.OperationDetailVat, this.modelForm)
    },

    canEditVat () {
      return this.canReadVat && hasFeature(EFeature.OperationDetailVatEdition, this.modelForm)
    },

    isValueDateVisible () {
      return hasFeature(EFeature.OperationDetailValueDate)
    },

    isAccountingDateVisible () {
      return this.modelForm.accounting_date && hasFeature(EFeature.OperationDetailAccountingDate)
    },

    isSettlementDateVisible () {
      return this.modelForm.settlement_date && hasFeature(EFeature.OperationDetailSettlementDate)
    }
  },

  async created () {
    this.modelForm = cloneDeep(this.model)
    this.loading = true
    const data = await this.operationStore.getOperationDetail(this.modelForm.uuid)

    Object.assign(this.modelForm, data || {})

    if (this.canReadVat) {
      this.vatRates = await this.operationStore.getOperationVATRates(this.modelForm.uuid)
      this.vatRates.unshift({
        value: -1,
        label: this.$i18n.t('general.total.label')
      })
    }

    this.loading = false
    this.operation = cloneDeep(this.modelForm)

    this.affectations = this.operation.affectations
    this.categories = this.operation.detail.categories
    this.attributions = this.operation.attributions
    this.vat = this.operation.detail.vat

    this.$bus.emit('drag-and-drop-ready')

    this.$nextTick(() => {
      if (this.sidePanel.initShowFile) this.showFile()
    })
  },

  mounted () {
    this.$bus.on('hide-sidepanel', this.onCloseSidepanel)
    this.$bus.on('document-analyze-success', this.onDocumentAnalyzed)
    this.$bus.on('on-upload-success', this.refreshOperation)
    this.$bus.on('on-update-compliance-success', this.refreshOperation)
    this.$bus.on('on-reject-expense', this.onRejectExpense)
    this.$bus.on('delete-success', this.refreshOperation)
    this.$bus.on('on-files-dropped', this.onFilesDropped)
    this.$bus.on('delete-category', this.onDeleteCategory)
    this.$bus.on('delete-affectation', this.onDeleteAffectation)
  },

  beforeUnmount () {
    this.$bus.off('hide-sidepanel', this.onCloseSidepanel)
    this.$bus.off('document-analyze-success', this.onDocumentAnalyzed)
    this.$bus.off('on-upload-success', this.refreshOperation)
    this.$bus.off('on-update-compliance-success', this.refreshOperation)
    this.$bus.off('on-reject-expense', this.onRejectExpense)
    this.$bus.off('delete-success', this.refreshOperation)
    this.$bus.off('on-files-dropped', this.onFilesDropped)
    this.$bus.off('delete-category', this.onDeleteCategory)
    this.$bus.off('delete-affectation', this.onDeleteAffectation)
  },

  methods: {
    hasFeature,
    getBadgeProps,
    formatIban,
    formatTitle (document) {
      const name = `${document.user.first_name} ${document.user.last_name}`

      let action
      if (document.action === 'add') action = this.$i18n.t('form.document_history.added')
      else action = this.$i18n.t('form.document_history.deleted')

      const file = document.document

      const title = this.$i18n.t('form.document_history.content', { name, action, file })
      return title
    },

    formatSubtitle (document) {
      const date = formatDateText(document.created_at)
      const hour = formatTime(document.created_at)

      const description = this.$i18n.t('form.document_history.date', { date, hour })
      return description
    },

    formatAmount,

    getCurrencySymbol,

    formatDateText,

    onCloseSidepanel () {
      this.closeFileViewer()
      if (this.processingAnalyze) {
        this.abortController.abort()
        showToastWarning(this.$i18n.t('message.warning.document.aborted_analyze'))
      }
    },

    closeFileViewer () {
      this.displayFileViewer = false
    },

    async claimProof () {
      const data = await this.operationStore.updateOperationProof(this.modelForm.uuid, 'claim')
      if (data) {
        showToastSuccess(this.$i18n.t('message.success.operation.claim_proof', { user: this.operation.credit_card.user.name }))
        this.onOperationProofUpdate(data)
      }
    },

    async reportLostProof () {
      const data = await this.operationStore.updateOperationProof(this.modelForm.uuid, 'lost')
      if (data) {
        showToastSuccess(this.$i18n.t('message.success.operation.lost_proof'))
        this.onOperationProofUpdate(data)
      }
    },

    async setProofRequired () {
      const data = await this.operationStore.updateOperationProof(this.modelForm.uuid, 'required')
      if (data) {
        showToastSuccess(this.$i18n.t('message.success.operation.proof_required'))
        this.onOperationProofUpdate(data)
      }
    },

    async setProofInvalid () {
      const data = await this.operationStore.updateOperationProof(this.modelForm.uuid, 'invalid')
      if (data) {
        showToastSuccess(this.$i18n.t('message.success.operation.proof_required'))
        this.onOperationProofUpdate(data)
      }
    },

    async setProofValid () {
      const data = await this.operationStore.updateOperationProof(this.modelForm.uuid, 'valid')
      if (data) {
        showToastSuccess(this.$i18n.t('message.success.operation.proof_valid'))
        this.onOperationProofUpdate(data)
      }
    },

    async setProofOptional () {
      const data = await this.operationStore.updateOperationProof(this.modelForm.uuid, 'optional')
      if (data) {
        showToastSuccess(this.$i18n.t('message.success.operation.proof_optional'))
        this.onOperationProofUpdate(data)
      }
    },

    async toggleOperationCompliance () {
      const data = await this.operationStore.updateOperationCompliance({ uuid: this.modelForm.uuid, compliance: !this.isCompliant })
      if (data) {
        await this.refreshOperation(data)
      }
    },

    claimOperationCompliance () {
      if (this.isCompliant) {
        this.appStore.showModal(ClaimNonCompliantOperation, { uuid: this.modelForm.uuid }, { wrapperClass: 'modal--xs' })
      } else {
        this.toggleOperationCompliance()
      }
    },

    onOperationProofUpdate (data) {
      Object.assign(this.modelForm, data)
      this.$bus.emit('operation-update-success')
    },

    async refreshOperation (response) {
      const data = await this.operationStore.getOperationDetail(this.modelForm.uuid)
      if (data) {
        Object.assign(this.modelForm, data)
        this.modelForm.reliability = data.reliability
        this.modelForm.documents_actions = data.documents_actions
        this.modelForm.documents_status = data.documents_status
        this.modelForm.compliant_at = data.compliant_at
        this.modelForm.is_compliant = data.is_compliant
        this.modelForm.required_fields = data.required_fields
        this.modelForm.can_update_compliance = data.can_update_compliance
        this.$bus.emit('operation-update-success')
      }
      if (response && response.documents) {
        this.modelForm.documents = response.documents
      }
    },

    onCategoriesSelected (value) {
      this.categories = value
    },

    onAffectationsSelected (value) {
      this.affectations = value
    },

    onChargeBackSelected (item) {
      this.operation.detail.charge_back = item.value
    },

    async onAttributionSelected (value) {
      this.attributions = value
    },

    showFile (index = 0) {
      if (this.isProofLost) { return }

      if (this.hasDocument || this.hasBill || (this.modelForm.expenseToValidate && !this.displayFileViewer)) {
        this.appStore.showFileViewer({
          content: (this.modelForm.expenseToValidate || this.hasDocument) ? this.modelForm.documents : [this.modelForm.bill],
          isMultiDoc: this.modelForm.expenseToValidate || this.hasDocument,
          canEdit: this.hasDocument && this.hasPermission(this.$permissions.operationsDocumentsWrite) && this.isEditable,
          canAnalyze: this.isEditable,
          operationId: this.modelForm.uuid,
          documentsUrl: `/operations/${this.modelForm.uuid}/documents`,
          defaultIndex: index,
          dataModel: this.modelForm
        })
        this.displayFileViewer = true
      } else {
        this.addDocument()
      }
    },

    addDocument () {
      this.appStore.showDropzoneModal(
        this.$i18n.tc('title.attach_file', 2),
        this.operation,
        {
          url: `/operations/${this.operation.uuid}/documents`,
          canAnalyzeFile: true
        })
    },

    async saveOperation () {
      const { valid } = await this.$refs.form.validate()

      if (!valid) {
        this.formSubmitted = true
        return
      }

      const detail = {
        categories: this.categories,
        guest: this.operation.detail.guest,
        nights: this.operation.detail.nights,
        service: this.operation.detail.service,
        charge_back: this.operation.detail.charge_back,
        fuel: this.operation.detail.fuel?.value || null,
        liters: this.operation.detail.liters,
        mileage: this.operation.detail.mileage,
        half_board_included: this.operation.detail.half_board_included,
        vat: this.operation.detail.vat
      }
      const operation = await this.operationStore.updateOperationDetails(this.modelForm.uuid, {
        comment: this.operation.comment,
        affectations: this.affectations,
        attributions: this.attributions,
        detail
      })

      if (operation) {
        Object.assign(this.modelForm, operation || {})
        this.operation = cloneDeep(this.modelForm)

        this.affectations = this.operation.affectations
        this.categories = this.operation.detail.categories
        this.attributions = this.operation.attributions
        this.vat = this.operation.detail.vat
      }
    },

    async onNewItemAdded (itemLabel) {
      const newCategory = await this.operationStore.addCategory(itemLabel)
      if (newCategory) {
        this.categories.push(newCategory)
        this.$bus.emit('on-new-item-added', newCategory)
      }
    },

    onDeleteCategory (itemId) {
      const index = this.categories.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.categories.splice(index, 1)
      }
    },

    async onNewAffectationAdded (itemLabel) {
      const newAffectation = await this.operationStore.addAffectation(itemLabel)
      if (newAffectation) {
        this.affectations.push(newAffectation)
        this.$bus.emit('on-new-item-added', newAffectation)
      }
    },

    onDeleteAffectation (itemId) {
      const index = this.affectations.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.affectations.splice(index, 1)
      }
    },

    analyzeDocument () {
      this.abortController = new AbortController()

      this.showFile()
      const operationToAnalyze = {
        operationUuid: this.modelForm.uuid,
        documentId: this.modelForm.documents[this.$refs.fileviewer.index].id
      }

      this.operationStore.analyzeOperationDocument(operationToAnalyze, this.abortController.signal)
    },

    onDocumentAnalyzed (data) {
      if (data.vat.length > 0) {
        this.vat = data.vat
        this.operation.detail.vat = data.vat
      }

      if (data.guest) {
        if (!this.hasRestaurantCategory) {
          const cat = this.filteredOperationCategories.find(category => category.name === 'restaurant')
          if (cat) {
            this.categories.push(cat)
            this.operation.detail.guest = data.guest
          }
        }
      }

      showToastSuccess(this.$t('message.success.analyze_complete'))
    },

    userFilter (user) {
      if (user.accepted_by) {
        user.uuid = user.accepted_by.uuid
      }
      return `${user.first_name} ${user.last_name}${user.role_label ? ` - ${user.role_label}` : ''}`
    },

    async onFilesDropped (files) {
      const documents = await this.operationStore.sendDocuments(this.modelForm.uuid, files)
      this.$emit('dropped-files-upload')

      if (documents) {
        this.modelForm.documents = [...documents]
        this.showFile(this.modelForm.documents.length - files.length)
        this.$bus.emit('on-upload-success', { documents })
      }
    },

    handleProofAction () {
      if (this.processingAnalyze) {
        return
      }
      if (this.displayFileViewer && this.hasDocument) {
        if (this.isProofInvalid) {
          this.$bus.emit('edit-file')
        } else if (!this.isProofLost) {
          this.analyzeDocument()
        }
      } else {
        this.showFile()
      }
    },

    onCategoryDetailChange (field, value) {
      field === 'service'
        ? this.operation.detail.service = value.name
        : this.operation.detail[field] = value
    },

    isMissingField (field) {
      return this.modelForm.required_fields?.some(i => i.includes(field)) ?? false
    },

    async requestMoreInfo () {
      const success = await this.expenseStore.requestMoreInformations(this.model.uuid)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.expense_control.request_informations'))
        this.hidePopover()
      }
    },

    async resetExpenseControl () {
      const success = await this.expenseStore.resetExpense(this.model.uuid)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.expense_control.reset'))
        this.onCloseSidepanel()
        this.$bus.emit('on-reset-expense')
        this.appStore.closeSidePanel()
      }
    },

    nonCompliantExpense () {
      this.appStore.showModal(ClaimNonCompliantOperation, { uuid: this.model.uuid }, { wrapperClass: 'modal--xs' })
    },

    onRejectExpense () {
      this.onCloseSidepanel()
      this.appStore.closeSidePanel()
    },

    async onAcceptExpense () {
      const success = await this.expenseStore.acceptExpense(this.modelForm)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.expense_control.accepted'))
        this.onCloseSidepanel()
        this.$bus.emit('on-accept-expense')
        this.appStore.closeSidePanel()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.collapsible-content
  min-height 0

.collapsible-wrapper
  display grid
  overflow hidden
  transition grid-template-rows $easeInOutSine 300ms
  grid-template-rows 0fr

  .sidepanel-block-body
    transition padding-bottom $easeInOutSine 300ms

  &:not(.open) .sidepanel-block-body
    padding-bottom 0

  &.open
    grid-template-rows 1fr

</style>
