<template>
  <div v-on-list-resize
       class="dashboard__block dashboard__block--table">
    <div class="dashboard__block__heading">
      <h3 class="title">
        {{ blockTitle }}
      </h3>
      <router-link v-if="isOperationLinkVisible"
                   class="view-more"
                   :to="{ name: 'operations' }">
        {{ t("button.see_more") }}
      </router-link>
    </div>
    <loader-spinner v-if="loading" />
    <ul v-else-if="operations?.length > 0"
        class="list-transaction">
      <li v-for="item in operations"
          :key="item.uuid"
          @click="showTransferDetail(item)">
        <span class="date">{{ formatDate(item.accounting_date) }}</span>
        <span class="name">{{ item.short_label ? item.short_label : item.label }}</span>
        <span class="amount right"
              :class="{ 'amount--credit': item.type === 'credit', 'amount--debit': item.type === 'debit' }">
          {{ formatAmount(item.amount, item.currency) }}
        </span>
        <span v-if="isDocumentColVisible(item)"
              class="attachment">
          <button v-tooltip="{ content: fileTooltip(item), theme: 'tooltip' }"
                  class="btn-link btn-attachment"
                  @click.prevent.stop="documentCheckMethod(item)">
            <ic-attachment-sm v-bind="{ [iconType(item)]: true }"
                              class="ic ic--16 ic--gray" />
          </button>
        </span>
      </li>
    </ul>
    <p v-else
       class="empty-text">
      {{ t("placeholder.no_operation.title") }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { EFeature, hasFeature } from '@/config/features'
import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import { formatDate } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { ModelOperation } from '@/models/Operation/ModelOperation'
import { useAppStore } from '@/stores/app'

import OperationDetail from '@/pages/operations/sidepanel/OperationDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcAttachmentSm from '@/components/svg/icons/ic-attachment-sm.vue'

const appStore = useAppStore()
const { t } = useI18n()

const props = defineProps<{
  isRevenue?: boolean | null,
  operations: ModelOperation[],
  loading: boolean
}>()

const blockTitle = computed(() => {
  if (props.isRevenue === true) { return t('title.recent_incomes') }
  if (props.isRevenue === false) { return t('title.recent_expenses') }
  return t('title.recent_operations')
})

const isOperationLinkVisible = computed(() => {
  return hasFeature(EFeature.SectionOperation)
})

function isDocumentColVisible (item: ModelOperation) {
  return hasPermission(permissions.operationsDocumentsWrite) || (hasPermission(permissions.operationsDocumentsRead) && hasDocument(item))
}

function showTransferDetail (operation: ModelOperation) {
  appStore.showSidePanel(OperationDetail, { model: operation }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true, canDragAndDrop: !isProofBill(operation) })
}

function fileTooltip (operation: ModelOperation) {
  if (operation.isProofLost) {
    return t('tooltip.lost_proof')
  }
  if (operation.isProofInvalid) {
    return t('tooltip.invalid_proof')
  }
  if (operation.isProofOptional) {
    return t('button.optional_proof')
  }
  if (isProofUploaded(operation)) {
    return t('tooltip.show_file', operation.documents.length)
  }
  if (isProofBill(operation)) {
    return t('tooltip.show_file')
  }
  return t('tooltip.attach_files')
}

function documentCheckMethod (operation: ModelOperation) {
  if (isProofUploaded(operation) || isProofBill(operation)) {
    appStore.showSidePanel(OperationDetail, { model: operation }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true, initShowFile: true })
  } else if (operation.isProofLost) {
    showTransferDetail(operation)
  } else {
    appStore.showDropzoneModal(
      t('title.attach_file', 2),
      operation,
      {
        url: `/operations/${operation.uuid}/documents`,
        canAnalyzeFile: true
      })
  }
}

function isProofUploaded (operation: ModelOperation) {
  return operation.hasDocuments
}

function isProofBill (operation: ModelOperation) {
  return operation.bill
}

function hasDocument (operation: ModelOperation) {
  return isProofUploaded(operation) || isProofBill(operation)
}

function iconType (operation: ModelOperation) {
  if (operation.isProofLost) {
    return 'danger'
  }
  if (operation.isProofInvalid) {
    return 'invalid'
  }
  if (operation.isProofOptional) {
    return 'optional'
  }
  if (isProofUploaded(operation) || isProofBill(operation)) {
    return 'document'
  }
  return 'attachment'
}
</script>
