<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="operations.length">
      <mobile-table v-if="isDeviceMobile"
                    :operations="operations" />
      <component-table v-else
                       class="table--hover table-operations table--fixed"
                       data-cy="operations.table">
        <thead>
          <tr>
            <template v-if="isCheckingAccount">
              <th class="col--sm-1">
                {{ t("table.date") }}
              </th>
              <th class="col--sm-4">
                {{ t("table.label") }}
              </th>
              <th class="col--sm-2">
                {{ t("table.type") }}
              </th>
              <th class="col--sm-2 right">
                {{ t("table.credit") }}
              </th>
              <th class="col--sm-2 right">
                {{ t("table.debit") }}
              </th>
              <th v-if="hasPermission($permissions.operationsDocumentsRead)"
                  class="col--sm-1 center">
                {{ t("table.documents") }}
              </th>
            </template>
            <template v-else-if="isCard">
              <th class="col--sm-1">
                {{ t("table.date") }}
              </th>
              <th class="col--sm-3">
                {{ t("table.label") }}
              </th>
              <th class="col--sm-2">
                {{ t("table.card") }}
              </th>
              <th class="col--sm-2">
                {{ t("label.card.holder") }}
              </th>
              <th class="col--sm-2 right">
                {{ t("table.amount") }}
              </th>
              <th v-if="hasPermission($permissions.operationsDocumentsRead)"
                  class="col--sm-1 center">
                {{ t("table.documents") }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="(items, date) in groupedOperations"
                    :key="date">
            <tr class="header-table-section">
              <td colspan="6">
                <span class="arrow">&rsaquo;</span>{{ date }}
              </td>
            </tr>
            <tr v-for="item in items"
                :key="item.uuid"
                class="is-link"
                :class="{ 'text-muted': item.isPending || item.isRejected }"
                :data-cy="`operations.item-${item.uuid}`"
                @click="showOperationDetail(item)">
              <template v-if="isCheckingAccount">
                <td class="text-muted nowrap">
                  {{ formatDate(item[sortingDate]) }}
                </td>
                <td class="text-overflow">
                  <div class="d-flex">
                    <core-badge v-if="item.isPending"
                                class="mr-1"
                                :theme="ECoreBadgeTheme.GrayLow"
                                :fill="ECoreBadgeFill.Shaded"
                                :value="t('general.to_come')" />
                    <span class="text-overflow col-label">{{ item.short_label ? item.short_label : item.label }}</span>
                  </div>
                </td>
                <td><span class="text-overflow col-type">{{ item.category_label }}</span></td>
                <td class="right">
                  <span v-if="item.type === 'credit'"
                        class="amount text-green">
                    {{ formatAmount(item.amount, item.currency) }}
                  </span>
                </td>
                <td class="right">
                  <span v-if="item.type === 'debit'"
                        class="amount text-red">
                    {{ formatAmount(item.amount, item.currency) }}
                  </span>
                </td>
              </template>
              <template v-else-if="isCard">
                <td class="text-muted nowrap">
                  {{ formatDate(item.created_at) }}
                </td>
                <td class="text-overflow">
                  <div class="d-flex">
                    <core-badge v-if="isOperationPreAuth(item)"
                                :theme="ECoreBadgeTheme.GrayLow"
                                :fill="ECoreBadgeFill.Shaded"
                                class="mr-1"
                                :value="t('general.pre_authorization')" />
                    <core-badge v-else-if="item.isPending"
                                v-tooltip="{ content: t('poptip.operation_to_come.content'), theme: 'poptip' }"
                                :theme="ECoreBadgeTheme.GrayLow"
                                :fill="ECoreBadgeFill.Shaded"
                                :value="t('general.to_come')"
                                class="mr-1" />
                    <core-badge v-else-if="item.isRejected"
                                :theme="ECoreBadgeTheme.Danger"
                                :fill="ECoreBadgeFill.Shaded"
                                :value="t('general.rejected')"
                                class="mr-1">
                      <template #default="{ value }">
                        <TooltipPoptip :value="value"
                                       :title="t('popover.operation.rejected.title')"
                                       :message="`${t('popover.operation.rejected.message')}<br>${item.reject_reason}`" />
                      </template>
                    </core-badge>
                    <span class="text-overflow col-label">{{ item.short_label ? item.short_label : item.label }}</span>
                  </div>
                </td>
                <td>**** {{ item.credit_card?.last_digits }}</td>
                <td class="col-cardholder">
                  <span class="text-overflow block">{{ item.credit_card?.user ? item.credit_card.user.name : '-' }}</span>
                </td>
                <td class="right">
                  <span class="amount"
                        :class="{ 'text-green': item.type === 'credit', 'text-through': item.isRejected }">
                    {{ formatAmount(item.amount, item.currency, { isNegative: item.type === 'debit' }) }}
                  </span>
                </td>
              </template>
              <td v-if="hasPermission($permissions.operationsDocumentsRead)"
                  class="center">
                <template v-if="(hasPermission($permissions.operationsDocumentsWrite) || (hasPermission($permissions.operationsDocumentsRead) && hasDocument(item))) && (isCard || isCheckingAccount) && !item.isRejected">
                  <button v-tooltip="{ content: fileTooltip(item), theme: 'tooltip' }"
                          class="btn-link btn-attachment"
                          @click.prevent.stop="documentCheckMethod(item)">
                    <component :is="proofIcon(item)"
                               class="ic ic--24 ic--gray"
                               :class="proofIcon(item)" />
                  </button>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </component-table>
      <component-pagination :pagination="operationsPagination" />
    </template>
    <component-placeholder v-else
                           :label="t(emptyPlaceholderLabel)"
                           :content="''" />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { CoreBadge, ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { formatDate, groupByDate } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { ModelOperation } from '@/models/Operation/ModelOperation'
import { useAppStore } from '@/stores/app'
import { useOperationStore } from '@/stores/operation'

import OperationDetail from '@/pages/operations/sidepanel/OperationDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcDocumentInvalid from '@/components/svg/icons/ic-document-invalid.vue'
import IcDocumentOptional from '@/components/svg/icons/ic-document-optional.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'
import ComponentTable from '@/components/Table.vue'
import TooltipPoptip from '@/components/TooltipPoptip.vue'

import MobileTable from './MobileTable.vue'

const { t } = useI18n()
const router = useRouter()
const appStore = useAppStore()
const operationStore = useOperationStore()
const { isDeviceMobile } = storeToRefs(appStore)
const { operations, operationsPagination } = storeToRefs(operationStore)

const loading = ref(true)

const props = withDefaults(defineProps<{
  type: string
  page: number
  filter: Record<string, unknown>
  cardUuid?: string | null
  emptyPlaceholderLabel?: string
  isClaimed?: boolean
}>(), {
  cardUuid: null,
  emptyPlaceholderLabel: 'placeholder.no_operation.title',
  isClaimed: false
})

const emit = defineEmits(['pagination-total-items'])

onMounted(() => {
  initData()
  bus.on('operation-update-success', reloadData)
  bus.on('on-upload-success', reloadData)
  bus.on('delete-success', reloadData)
})

onBeforeUnmount(() => {
  bus.off('operation-update-success', reloadData)
  bus.off('on-upload-success', reloadData)
  bus.off('delete-success', reloadData)
})

watch(() => props.page, () => {
  initData()
})

watch(() => props.filter, () => {
  resetData()
})

watch(() => operationsPagination.value.totalItems, () => {
  emit('pagination-total-items', operationsPagination.value.totalItems)
})

const groupedOperations = computed(() => {
  return groupByDate(operations.value, sortingDate.value, { dateFieldFormat: sortingDate.value === 'created_at' ? 'unix' : 'YYYY-MM-DD' }) as [[ModelOperation]]
})

const sortingDate = computed(() => {
  return isCard.value ? 'created_at' : 'accounting_date'
})

const isCard = computed(() => {
  return props.type === 'cards'
})

const isCheckingAccount = computed(() => {
  return props.type === 'checking-account'
})

async function initData (showLoader = true) {
  loading.value = showLoader

  const filter = { ...props.filter }
  if (isCard.value) { filter.card = true }
  if (props.cardUuid) { filter.card_uuid = props.cardUuid }
  props.isClaimed ? await operationStore.getClaimedOperations(props.page, filter) : await operationStore.getOperations(props.page, filter)

  loading.value = false
}

function reloadData () {
  initData(false)
}

function resetData () {
  if (props.page > 1) {
    const routeName = router.currentRoute.value.name
    if (routeName) {
      router.push({ name: routeName })
    } else {
      initData()
    }
  } else {
    initData()
  }
}

function showOperationDetail (operation: ModelOperation, showFileViewer = false) {
  appStore.showSidePanel(
    OperationDetail,
    { model: operation },
    {
      wrapperClass: 'sidepanel--md',
      backgroundOverlay: true,
      canDragAndDrop: !operation.isRejected && !isProofBill(operation),
      initShowFile: showFileViewer
    }
  )
}

function fileTooltip (operation: ModelOperation) {
  if (operation.isProofLost) {
    return t('tooltip.lost_proof')
  }
  if (operation.isProofInvalid) {
    return t('tooltip.invalid_proof')
  }
  if (operation.isProofOptional) {
    return t('button.optional_proof')
  }
  if (operation.hasDocuments) {
    return t('tooltip.show_file', operation.documents.length)
  }
  if (isProofBill(operation)) {
    return t('tooltip.show_file')
  }
  return t('tooltip.attach_files')
}

function documentCheckMethod (operation: ModelOperation) {
  if (operation.isProofLost) {
    return showOperationDetail(operation)
  }
  if (operation.hasDocuments || isProofBill(operation)) {
    return showOperationDetail(operation, true)
  }
  return appStore.showDropzoneModal(
    t('title.attach_file', 2),
    operation,
    { url: `/operations/${operation.uuid}/documents`, canAnalyzeFile: true })
}

function isOperationPreAuth (operation: ModelOperation) {
  return operation.is_pre_auth
}

function isProofBill (operation: ModelOperation) {
  return operation.bill
}

function proofIcon (operation: ModelOperation) {
  if (operation.isProofLost) {
    return IcWarning
  }
  if (operation.isProofInvalid) {
    return IcDocumentInvalid
  }
  if (operation.isProofOptional) {
    return IcDocumentOptional
  }
  if (operation.hasDocuments || isProofBill(operation)) {
    return IcDocument
  }
  return IcAttachment
}

function hasDocument (operation: ModelOperation) {
  return operation.hasDocuments || isProofBill(operation)
}
</script>
